import React from "react"
import StyledFooter from "./styles"

const Footer = ({ theme }) => (
  <StyledFooter theme={theme}>
    {/*
      <p>
        Made with &#10084; by <a href="https://github.com/feux07">feux07</a>
      </p>
      */}
  </StyledFooter>
)

export default Footer
