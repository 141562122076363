import React,{useEffect} from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import {  Breadcrumb } from "semantic-ui-react"
import StyledLayout from "./styles"
import Footer from "src/components/Footer"
import detectPassiveEvents from 'detect-passive-events';


const Layout = ({ children, theme, withBreadCrumb, activePage = "", textAlign }) =>{
  
  useEffect(()=>{
    if (detectPassiveEvents.hasSupport === true) {
      document.addEventListener('wheel', (evt) => {   
      }, {   
         capture: true,   
         passive: true 
      })
    }



   return ()=>{
    document.removeEventListener('wheel', (evt) => {   
    }, {   
       capture: true,   
       passive: true 
    })
   }

  });
  
  return ( 

  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            subTitle
            icons {
              name
              url
            }
          }
        }
      }
    `}
    render={data => (
      <StyledLayout textAlign={textAlign}>
        {withBreadCrumb && (
          <Breadcrumb>
            <Breadcrumb.Section >
              <Link to="/">Home</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section active>{activePage}</Breadcrumb.Section>
          </Breadcrumb>
        )}

        <div style={{ width: "100%" }}>{children}</div>
        <Footer theme={theme}></Footer>
      </StyledLayout>
    )}
  />
)
        }
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
