import { createGlobalStyle } from "styled-components"
import "semantic-ui-css/semantic.min.css"

const theme = {
  mainColor: "#00b300",
  bgColor: "#000000",
  mainTitleColor: "#00b300", //'#e0e0e0'
  mainTitleFont: "Audiowide, cursive",
  subTitleColor: "#00b300",
  subTitleFont: "Special Elite, cursive",
  iconColor: "#00b300",
  iconBgColor: " black",
  iconColorOnActive: "#00b300",
  keepInTouchTextColor: "#00b300",
  keepInTouchFontFanily: "Special Elite, cursive",
}

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

html{
 background: #000;
 
}

html, body{
  overflow: ${({ overflow }) => (overflow ? overflow : "auto")};
  touch-action: ${({ touchAction }) =>
    touchAction ? touchAction : "auto"}  !important;
  
  background:  ${theme.bgColor} !important;
  color: ${theme.mainColor} !important;
  font-size: 0.9vw!important;
 
  
}
body>div{
  width:100%;
}
body{
  padding : 2rem!important;
 
  display:flex;
  justify-content:center;
  margin-top: ${({ bodyMarginTop }) =>
    bodyMarginTop ? bodyMarginTop : "20vh"}  !important;
  background:transparent !important;
}

a:link {
  color:  ${theme.mainColor};

}

a:visited {
  color:  ${theme.mainColor};

}

a:hover {
  color:  ${theme.mainColor};

}

a:active {
  color:  ${theme.mainColor};

}

::selection {
    background-color: #00b300!important;
    color: black!important;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

@media (max-width: 1200px) { 
  html,body{
    
    font-size: calc(7px + (26 - 14) * ((100vw - 200px) / (1200 - 200)))!important;
  }
 }
`
export { theme, GlobalStyle }
/*
@media (max-width: 575.98px) { 
  html{
    font-size: 8px!important;
  }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) { 
  html{
    font-size: 13px!important;
  }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1920px){  
  html{
    font-size: 14px!important;
  }
}
@media (min-width: 2159px) {  
  html{
    font-size: 28px!important;
  }
}
*/
