import styled from "styled-components"

const StyledFooter = styled.footer`
  margin-top: 6rem;
  font-size: 1.2rem;
  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.mainColor}!important;
    font-family: ${({ theme }) => theme.subTitleFont}!important;
  }
  p > a {
    color: ${({ theme }) => theme.mainColor}!important;
    margin: 0.3rem;
    font-size: 1.1rem;
  }
`
export default StyledFooter
