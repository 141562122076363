import styled from "styled-components"

const StyledLayout = styled.div`
  text-align: ${({ textAlign }) => textAlign ? textAlign :  'center'};
  background: transparent;
  z-index: 1;
  margin-bottom: 2rem;
  .ui.breadcrumb {
    width: 100%;
    text-align: left;
    margin-bottom: 3rem;
    font-size: 1.3rem;
  }
  .ui.breadcrumb *,
  .divider {
    color: ${({ theme }) => theme.mainColor}!important;
    font-weight: bold;
    font-family: ${({ theme }) => theme.subTitleFont};
  }
  .ui.breadcrumb .divider {
    opacity: 1 !important;
    font-size: 1.2rem;
  }
`
export default StyledLayout
